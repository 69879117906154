import Vue from 'vue'
import Login from './Login.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
var port = window.location.port;
if(port == '8080'){
	Vue.prototype.$hostUrl = 'http://localhost/insurefriend-admin/api/';
} else if(port == ''){
	Vue.prototype.$hostUrl = 'https://mag.carigoapp.com/api/';
}

new Vue({
	vuetify,
	router,
	render: h => h(Login)
}).$mount('#app')
