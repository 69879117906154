<template>
    <div id="app">
        <v-app id="inspire" :style="{background: '#FFC324'}">
            
            <v-row v-show="showAll">
                <v-col cols="12" offset-md="2" md="8">

                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-card class="mx-auto my-12" max-width="400" elevation="5" v-show="showLogin">
                            <v-container>
                                <v-row no-gutters>
                                    <v-col cols="12" class="text-center text-h4 my-5">
                                        <v-img src="/images/logo_1.png"></v-img>
                                    </v-col>
                                    <v-col cols="3" md="3" class="pt-5">
                                        Username :
                                    </v-col>
                                    <v-col cols="9" md="9">
                                        <v-text-field 
                                            v-model="username"
                                            :rules="[rules.required]"
                                            required></v-text-field>
                                    </v-col>
                                    <v-col cols="3" md="3" class="pt-5">
                                        Password :
                                    </v-col>
                                    <v-col cols="9" md="9">
                                        <v-text-field v-model="password" 
                                            :rules="[rules.required, rules.min]" required
                                            :append-icon="show1? 'mdi-eye' : 'mdi-eye-off'" 
                                            :type="show1 ? 'text' : 'password'"
                                            @click:append="show1 = !show1"></v-text-field>
                                    </v-col>
                                    <v-col v-show="false" cols="12" offset="3" offset-md="3" md="3">
                                        <v-switch v-model="remember" label="Remember"></v-switch>
                                    </v-col>
                                    <v-col md="12" class="text-center my-5">
                                        <v-btn class="mr-4" color="primary" @click="login()">Login</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-form>

                    <v-form v-model="valid2" ref="form2" lazy-validation>
                        <v-card class="mx-auto my-12" max-width="400" elevation="5" v-show="showNew">
                            <v-container>
                                <v-row no-gutters>
                                    <v-col cols="12" md="12" class="text-center text-h4 my-5">
                                        Create Password
                                    </v-col>
                                    <v-col cols="12" offset-md="1" md="10">
                                        <v-text-field v-model="newPassword" label="Password" 
                                            :rules="[rules.required, rules.min]" hint="At least 8 characters" required
                                            :append-icon="show2? 'mdi-eye' : 'mdi-eye-off'" 
                                            :type="show2 ? 'text' : 'password'"
                                            @click:append="show2 = !show2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" offset-md="1" md="10">
                                        <v-text-field v-model="conPassword" label="Confirm Password" 
                                            :rules="[rules.required, rules.min]" hint="At least 8 characters" required
                                            :append-icon="show3? 'mdi-eye' : 'mdi-eye-off'" 
                                            :type="show3 ? 'text' : 'password'"
                                            @click:append="show3 = !show3"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="12" class="text-center my-5">
                                        <v-btn class="mr-4" color="primary" @click="createPassword()">Confirm</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-form>

                    <v-snackbar v-model="alert" top right :color="color">
                        {{ message }}
                        <template>
                            <v-btn text small fab @click="alert = false"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                        </template>
                    </v-snackbar>

                </v-col>
            </v-row>

        </v-app>
    </div>
</template>

<script>

    export default {
        data: function() {
            return {
                showAll:true,
                valid:true,
                valid2:true,
                showLogin:true,
                showNew:false,
                username:'',
                password:'',
                newPassword:'',
                conPassword:'',
                remember:false,
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                },
                show1:false,
                show2:false,
                show3:false,
                alert:false,
                message:'',
                color:'',
            };
        },

        beforeCreate(){
            var app = this;
            let formData = new FormData();
            formData.append('mode', 'begin');
            this.$axios.post(this.$hostUrl+'set/', formData)
            .then(function (response) {
                if(!response.data){
                    app.message = "ERROR Please contact admin"
                    app.color = "error"
                    app.alert = true
                }else{
                    var output = response.data
                    console.log(output)
                    if(output == 'pass'){
                        window.location.href = "/"
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        
        methods: {

            login() {
                if(!this.$refs.form.validate() && !app.pass ) return;
                var app = this;
                let formData = new FormData();
                formData.append('username', app.username);
                formData.append('password', app.password);
                formData.append('remember', app.remember);
                formData.append('mode', 'login');
                this.$axios.post(this.$hostUrl+'set/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = "ERROR Please contact admin"
                        app.color = "error"
                        app.alert = true
                    }else{
                        var output = response.data
                        if(output == 'no'){
                            app.message = "Username or Password not correct"
                            app.color = "error"
                            app.alert = true
                        } else if(output == 'new'){
                            app.message = "Please create Password"
                            app.color = "success"
                            app.alert = true
                            app.showLogin = false
                            app.showNew = true
                        } else {
                            window.location.href = "/"
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            createPassword() {
                if(!this.$refs.form2.validate()) return;
                var app = this;
                if(app.newPassword != app.conPassword){
                    app.message = "Password not match"
                    app.color = "error"
                    app.alert = true
                    return;
                }
                let formData = new FormData();
                formData.append('username', app.username);
                formData.append('password', app.newPassword);
                formData.append('mode', 'createPassword');
                this.$axios.post(this.$hostUrl+'set/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = "ERROR Please contact admin"
                        app.color = "error"
                        app.alert = true
                    }else{
                        var output = response.data
                        // console.log(output)
                        if(output == "success"){
                            app.message = "Create password success"
                            app.color = "success"
                            app.alert = true
                            app.showNew = false
                            app.showLogin = true
                            app.password = ''
                        }else{
                            app.message = output
                            app.color = "error"
                            app.alert = true
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
        }
    }

    
</script>